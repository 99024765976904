<template>
    <!-- create dialog using vuetify for data entry -->
    <v-container>
        <v-form v-model="valid" ref="form" @submit.prevent="submit" lazy-validation>
            <v-card class="pt-8">
                <v-card-text>
                    <VRow v-if="!isDis">
                        <v-col md="3">
                            <v-text-field :dark="$store.state.isDarkMode" dense :label="$t('from-date-bill')" type="date" outlined
                                v-model="form.created_from">
                            </v-text-field>
                        </v-col>
                        <v-col md="3">
                            <v-text-field :dark="$store.state.isDarkMode" dense :label="$t('to-date-bill')" type="date" outlined
                                v-model="form.created_to">
                            </v-text-field>
                        </v-col>
                        <v-col md="3">
                            <AutoCompleteField :label="$t('student name')" end-point="/accounting/account/auto-complete" outlined
                                               :hint="$t('requird')" v-model="form.student"/>
                        </v-col>
                        <v-col md="3">
                            <AutoCompleteField :label="$t('cost center')" end-point="/accounting/cost-center/auto-complete" outlined
                             v-model="form.cost_center"/>
                        </v-col>
                    </VRow>
                    <v-row v-if="!isDis">
                        <v-col md="3">
                            <v-text-field :dark="$store.state.isDarkMode" dense :label="$t('from-due-date')" type="date" outlined
                                v-model="form.due_from">
                            </v-text-field>
                        </v-col>
                        <v-col md="3">
                            <v-menu ref="menu1" v-model="dateMenu" :close-on-content-click="false"
                                transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field :dark="$store.state.isDarkMode" :label="$t('to-due-date')" outlined dense persistent-hint
                                        prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"
                                        v-model="form.due_to"></v-text-field>
                                </template>
                                <v-date-picker v-model="form.due_to" no-title @input="dateMenu = false"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col md="3">
                            <v-text-field :dark="$store.state.isDarkMode" dense :label="$t('numbill')" type="text" outlined v-model="form.invoice">
                            </v-text-field>
                        </v-col>
                        <v-col md="2">
                            <v-btn color="blue" style="width: 100%; color: white" @click="submit" type="button">
                                {{  $t('hr.payment.grab')  }}
                            </v-btn>
                        </v-col>
                        <v-col md="1">
                            <v-btn @click="cancelPay" type="button">
                                 {{ $t('cancel') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="my-8" v-if="!isDis"></v-divider>
                    <v-form ref="form2" @submit.prevent="submit" lazy-validation>
                        <v-row v-if="isThereCalculation">
                            <v-col sm="3">
                                <AutoCompleteField :label="$t('hr.loan.cash')" end-point="/accounting/account/auto-complete" :cash="1" outlined 
                                dense v-model="paymentSubmit.cash_account_id" :rules="requiredRule()" />
                            </v-col>
                            <v-col sm="3">
                                <v-text-field :value="summary" readonly dense outlined 
                                    :label="$t('hr.payment.summary')"></v-text-field>
                            </v-col>
                            <v-col sm="2" v-if="!isDis">
                                <v-btn width="100%" @click="submitPayments" :loading="loadingSubmit" :disabled="!isThereCalculation" class="mx-1"
                                    style="color: white" color="blue">
                                    {{ $t('save') }}
                                </v-btn>
                            </v-col>
                            <v-col sm="2">
                                <v-btn width="100%" @click="cancelPay" :disabled="!isThereCalculation" outlined
                                    class="mx-1">
                                    {{ $t('cancel') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="isThereCalculation">
                            <v-col sm="3">
                                <v-menu :disabled="isDis" ref="menu1" v-model="dateMenu1"
                                    :close-on-content-click="false" transition="scale-transition" offset-y
                                    max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attrs }" :disabled="isDis">
                                        <v-text-field :label="$t('date')" outlined dense persistent-hint
                                            prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"
                                            v-model="date"></v-text-field>
                                    </template>
                                    <v-date-picker :disabled="isDis" v-model="date" no-title
                                        @input="dateMenu1 = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col sm="9">
                                <v-text-field dense :disabled="isDis" :label="$t('hr.employee.note')" type="text"
                                    outlined v-model="desc"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row>
                        <v-col md="12">
                        </v-col>
                        <v-data-table :dark="$store.state.isDarkMode" :headers="headers" id="table" 
                            class="flex-grow-1" :items="dataTable" :loading="loadingDataTable"
                            style="border: 1px solid #999" hide-default-footer>
                            <template v-slot:header.actions="{ headers }">                               
                                    <v-checkbox
                                        :label="$t('select all')"
                                        class="mx-4 mb-4"
                                        v-model="selected_all"
                                        @change="checkSelectedAll"
                                        hide-details
                                    ></v-checkbox>
                            </template>
                            <template v-slot:item.rest="{ item }">
                                <v-text-field value="0" v-model="item.rest" dense type="number" class="mt-5" outline
                                    :rules="requiredRule"></v-text-field>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <td>
                                    <v-checkbox
                                        :disabled="item.app_order || item.send_order"
                                        class="mx-4 mb-4"
                                        v-model="item.selected"
                                        @change="checkSelected"
                                        hide-details
                                    ></v-checkbox>
                                </td>
                            </template>
                            <template v-slot:item.actions_send="{ item }">
                                <td>
                                    <v-btn :disabled="item.app_order || item.selected" :color="colorSend(item)" @click="sendOrder(item)" icon>
                                        <v-icon class="ml-1">mdi-near-me</v-icon>
                                    </v-btn>
                                </td>
                            </template>
                        </v-data-table>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-form>
    </v-container>
</template>

<script>
import { requiredRule } from "@/helpers/validation-rules";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import AutoCompleteField from "../../components/core/AutoCompleteField.vue";

export default {
    name: 'newPayInstallment',
    components: {
        AutoCompleteField,
    },
    data() {
        return {
            isThereCalculation: false,
            loadingDataTable: false,
            loadingSubmit: false,
            dataTable: [],
            selected_all: false,
            dateMenu1: false,
            dateMenu: false,
            localItem: null,
            dialogValue: this.value,
            search: '',
            valid: false,
            date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            desc: '',
            form: {
                created_from: null,
                created_to: null,
                due_from: null,
                due_to: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10),
                invoice: null,
                cost_center: null,
                student: null,
            },
            paymentSubmit: {
                cash_account_id: '',
            }
        }
    },
    methods: {
        
        ...mapActions({
            restorePayment: 'payinstallment/restorePayment',
            submitPaymentsAction: 'payinstallment/submitPaymentsAction',
        }),
        
        checkSelected(){

        },
        sendOrder(el){
            el.send_order = !el.send_order;
        },
        colorSend(el){
            if(el.app_order) return 'red';
            if(el.send_order) return 'green';
            return 'gray'
        },
        checkSelectedAll(){
            if (this.selected_all) {
        this.dataTable.forEach((el, index) => {
                if(!el.app_order){
                    el.selected = true;
                }
                });
            } else {
                this.dataTable.forEach((el, index) => {
                el.selected = false;
                });
            }
        },
       
    
        requiredRule() {
            return requiredRule
        },
        async submit() {
            const valid = await this.$refs.form.validate()
            if (valid) {
                let params = this.form;
                this.loadingDataTable = true;
                const response = await axios.get('/inventories/installment/index', {
                    params
                });
                if(response.status==200){
                    const paymentData = response.data?.data;
                    if (Array.isArray(paymentData)) {
                        this.dataTable = paymentData.map((el) => ({
                            ...el,
                            salary: el.amount,
                            selected: false,
                            send_order: false,
                        }));
                    }
                    this.isThereCalculation = true;
                }
            }
            this.loadingDataTable = false;
        },
        async submitPayments() {
            this.loadingSubmit = true;
            const valid = await this.$refs.form2.validate() && await this.$refs.form.validate();
            if (valid) {
                let temp = [];
                for(let i = 0; i < this.dataTable.length; i++) {
                    if(this.dataTable[i].send_order || this.dataTable[i].selected) {
                        temp.push(this.dataTable[i]);
                    }
                }
                let res = await this.submitPaymentsAction({
                    ...this.paymentSubmit,
                    document: {
                        date: this.date,
                        desc: this.desc,
                    },
                    payments: temp.map(el => ({ id: el.id, salary: el.rest ,send_order: el.send_order, }))
                })
                if (res.status==200) { console.log(res);
                    this.dialogValue = false;
                    this.localItem = null;
                    this.value = false;
                    this.isDis = false;
                    this.item = null;
                    this.$Notifications(
                    this.$t('The installment paid successfully'),
                        { rtl: true, timeout: 1000 },
                        "success"
                    );
                    this.$router.go(-1);
                }
            }
            this.localItem = null;
            this.value = false;
            this.isDis = false;
            this.loadingSubmit = false;
        },
        cancelPay() {
            this.item = null;
            this.localItem = null;
            this.value = false;
            this.isDis = false;
            this.dataTable = [];
            this.$router.go(-1);
        }

    },
    mounted() {

    },

    props: {
        value: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: null
        },
        isDis: {
            type: Boolean,
            default: false
        },
    },
    computed: {
        ...mapGetters({
            data: 'payinstallment/paymentData',
            headers: 'payinstallment/paymentHeaders',
            cashs: 'employees/cashs',
        }),
        summary() {
            // if (this.isDis) { return this.item.payment; }
            let val = 0;
            this.dataTable.forEach((el, index) => {
                if(el.selected && el.rest != null){
                   val += parseInt(el.rest);
                }
                });
            return val;
        },
    },
    watch: {
        value(newValue) {
            // Update the data property when the prop changes
            this.dialogValue = newValue;
        },
        dialogValue(newValue) {
            // Emit an event to update the prop when the data property changes
            this.$emit('input', newValue);
        },
        item(nV) {
            this.localItem = { ...newValue };
            this.form = { ...this.form, ...nV }
        },
    },
    async created() {
        this.item = null;
            this.localItem = null;
            this.value = false;
           
        if (this.isDis) {
            this.localItem = { ...this.item };
            this.date = this.item.date;
            this.desc = this.item.desc;
            await this.fetchCashs('');
            this.paymentSubmit.cash_account_id = this.item.account.id;
        }
    },
}
</script>

<style scoped></style>